"use strict";
import "owl.carousel";
$(function () {
  "use strict";
  $.ajaxSetup({
    cache: false,
    data: {
      csrftoken: Cookies.get(cookie_pre + "csrfcookie"),
    },
  });
  // INITIALIZE CAROUSEL
  // in another js file, far, far away
  let owl = $(".owl-carousel");
  owl.owlCarousel({
    margin: 20,
    dots: true,
    nav: true,
    navText: [
      "<em class='fa fa-chevron-left' alt='previous'></em>",
      "<em class='fa fa-chevron-right' alt='next'></em>",
    ],
    stagePadding: 0,
    autoWidth: false,
    center: false,
    autoplay: false,
    loop: false,
    smartSpeed: 400,
    responsiveClass: false,
    responsive: {
      0: {
        items: 1,
        slideBy: 1,
      },
      650: {
        items: 2,
        slideBy: 2,
      },
      1600: {
        items: 3,
        slideBy: 3,
      },
    },
    onInitialized: function () {
      $(".owl-carousel")
        .find(".owl-prev")
        .attr("role", "button")
        .attr("title", "Previous");
      $(".owl-carousel")
        .find(".owl-next")
        .attr("role", "button")
        .attr("title", "Next");
      $(".owl-carousel, .owl-prev, .owl-next").attr("tabindex", "0");

      new Foundation.Equalizer(owl);
    },
  });

  //========================================================//
  // POPULATE CONGRESSES
  //========================================================//
  populate_congresses();

  //========================================================//
  // SORTBY
  //========================================================//
  $(".banner-bar .ae-select-content").text(
    $(".dropdown-menu > li.selected").text()
  );

  $(".banner-bar .dropdown-menu > li").on("click", function (_event) {
    $(".dropdown-menu").toggleClass("ae-hide");
    let order = $(this).data("field");
    $("#congressContainer").attr("data-order", order);
    //reset the direction
    $("#congressContainer").attr("data-dir", "ASC");

    $(".ae-select-content").text($(this).text());
    $(".dropdown-menu > li").removeClass("selected");
    $(this).addClass("selected");

    populate_congresses();
  });

  $(".banner-bar .ae-dropdown").hover(
    function () {
      $(".dropdown-menu").removeClass("ae-hide");
    },
    function () {
      $(".dropdown-menu").addClass("ae-hide");
    }
  );

  $(".banner-bar .ae-dropdown, .dropdown-menu li ").on("click", function () {
    $(".dropdown-menu").toggleClass("ae-hide");
  });

  //========================================================//
  // SORTBY DIRECTION
  //========================================================//
  $(".main_content").on("click", "#btnSortDirection", function (e) {
    e.preventDefault();

    let current = $("#congressContainer").attr("data-dir");

    if (current == "ASC") {
      $("#congressContainer").attr("data-dir", "DESC");
      $(this).attr("title", "Sort ascending");
    } else {
      $("#congressContainer").attr("data-dir", "ASC");
      $(this).attr("title", "Sort Descending");
    }
    populate_congresses();
  });
});

//========================================================//
// 1. POPULATE CONGRESSES
//========================================================//
window.populate_congresses = function populate_congresses() {
  let search = $("#search").val();
  let congress_id = $("#congressContainer").data("id");
  let view = $("#congressContainer").attr("data-view");
  let order = $("#congressContainer").attr("data-order");
  let dir = $("#congressContainer").attr("data-dir");
  let preview = $("#congressContainer").attr("data-preview");

  $("#congressContainer").html(
    '<div class="text-center preloader"><em class="fa fa-spinner fa-spin fa-5x fa-fw fa-pulse"></em><span class="sr-only">Loading...</span></div>'
  );
  setTimeout(function () {
    $.ajax({
      type: "POST",
      url: "/ajax/loadCongressCards/",
      data: {
        congress_id: congress_id,
        search: search,
        view: view,
        preview: preview,
        order: order,
        dir: dir,
      },
    }).done(function (data) {
      $("#congressContainer").html(data);
    });
  }, 10);
};
